.snapchat-login {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .snapchat-login h2 {
    font-size: 2rem;
    color: #000;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  input[type="text"],
  input[type="password"] {
    padding: 12px 15px;
    border-radius: 25px;
    border: 1px solid #ddd;
    font-size: 1rem;
    text-align: center;
  }
  
  input::placeholder {
    color: #888;
  }
  
  button {
    padding: 12px;
    background-color: #000;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #333;
  }
  
  button:focus,
  input:focus {
    outline: none;
    border-color: #000;
  }