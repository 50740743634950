/* src/components/SocialMediaPopup.css */

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .popup-box {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .popup-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .popup-text {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  .social-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .social-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 0.9rem;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .social-button:hover {
    background-color: #0056b3;
  }
  
  .social-icon {
    font-size: 1.2rem;
    margin-right: 10px;
  }
  
  @media (max-width: 480px) {
    .popup-box {
      padding: 15px;
    }
  
    .popup-title {
      font-size: 1.1rem;
    }
  
    .popup-text {
      font-size: 0.85rem;
    }
  
    .social-button {
      font-size: 0.85rem;
      padding: 8px;
    }
  }
  