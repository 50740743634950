/* src/components/InstagramLogin.css */

.instagram-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    background-color: #fafafa;
    height: 100vh;
    padding-top: 30px;
    color: #262626;
  }
  
  .instagram-header {
    margin-bottom: 20px;
  }
  
  .instagram-logo {
    width: 175px; /* Instagram logo width */
    height: auto;
  }
  
  /* Form container */
  .login-form {
    background-color: #ffffff;
    width: 90%;
    max-width: 350px;
    border: 1px solid #dbdbdb;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    margin: 8px 0;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
    font-size: 0.9rem;
    color: #262626;
    background-color: #fafafa;
  }
  
  .login-form input::placeholder {
    color: #999;
  }
  
  .login-form button[type="submit"] {
    width: 100%;
    background-color: #3897f0;
    color: white;
    padding: 10px;
    margin-top: 10px;
    border: none;
    border-radius: 3px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
  }
  
  .login-form button[type="submit"]:hover {
    background-color: #357ae8;
  }
  
  /* Divider with OR text */
  .divider {
    display: flex;
    align-items: center;
    width: 100%;
    color: #8e8e8e;
    font-size: 0.8rem;
    margin: 20px 0;
  }
  
  .divider::before,
  .divider::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #dbdbdb;
    margin: 0 10px;
  }
  
  .login-with-facebook {
    background-color: transparent;
    color: #385185;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    border: none;
  }
  
  .forgot-password {
    color: #00376b;
    font-size: 0.8rem;
    margin-top: 15px;
    text-decoration: none;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }
  
  /* Responsive design adjustments */
  @media (max-width: 480px) {
    .login-form {
      width: 100%;
      padding: 15px;
    }
  
    .instagram-logo {
      width: 150px;
    }
  
    .login-form input {
      padding: 8px;
      font-size: 0.85rem;
    }
  
    .login-form button[type="submit"] {
      padding: 8px;
      font-size: 0.9rem;
    }
  }
  