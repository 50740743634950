/* src/components/XLogin.css */

.x-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    font-family: Arial, sans-serif;
  }
  
  .x-login h2 {
    color: #1da1f2; /* X/Twitter blue color */
    font-size: 2rem;
  }
  
  .x-login form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
  }
  
  .x-login input {
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .x-login button {
    padding: 10px;
    background-color: #1da1f2; /* X/Twitter blue */
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  