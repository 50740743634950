/* src/components/FacebookLogin.css */

.facebook-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    color: #1c1e21;
    background-color: #f0f2f5;
    height: 100vh;
    padding-top: 20px;
  }
  
  .facebook-header {
    background-color: #1877f2; /* Facebook blue */
    color: white;
    width: 100%;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: lowercase;
  }
  
  .login-container {
    background-color: white;
    width: 90%;
    max-width: 400px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .login-container input {
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .login-container input::placeholder {
    color: #606770;
  }
  
  .login-container button {
    background-color: #1877f2; /* Facebook blue */
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
  }
  
  .login-container button:hover {
    background-color: #145dbf; /* Darker blue on hover */
  }
  
  .forgot-password {
    color: #1877f2;
    font-size: 0.9rem;
    margin-top: 10px;
    text-decoration: none;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }
  
  .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 20px 0;
  }
  
  .create-account {
    background-color: #42b72a; /* Facebook green for account creation */
    color: white;
    padding: 12px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
  }
  
  .create-account:hover {
    background-color: #36a420; /* Darker green on hover */
  }
  
  /* Responsive for small screens */
  @media (max-width: 480px) {
    .facebook-login {
      padding-top: 10px;
    }
  
    .login-container {
      width: 95%;
    }
  
    .facebook-header {
      font-size: 1.2rem;
    }
  
    .login-container input, .login-container button {
      padding: 10px;
      font-size: 0.9rem;
    }
  }
  