.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* src/App.css */

.App {
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.profile-header {
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  position: relative;
  background-color: #f8f8f8;
}

.banner {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.profile-info {
  position: relative;
  text-align: left;
  padding: 20px;
}

.profile-pic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  left: 20px;
  border: 3px solid white;
}

.profile-details {
  margin-left: 100px;
}

.profile-details h2 {
  font-size: 1.5rem;
  margin: 0;
  display: flex;
  align-items: center;
}

.verified {
  color: #1da1f2; /* Twitter blue for verified check */
  font-size: 1rem;
  margin-left: 5px;
}

.username {
  color: #666;
  font-size: 0.9rem;
  margin: 5px 0;
}

.status {
  color: #28a745; /* Green color for "Available now" status */
  font-weight: bold;
}

.bio {
  font-size: 0.9rem;
  color: #333;
  line-height: 1.4;
  margin-top: 10px;
}

.more-info {
  font-size: 0.85rem;
  color: #1da1f2;
  text-decoration: none;
}

.stats {
  display: flex;
  justify-content: space-around;
  padding: 15px 0;
  font-size: 1rem;
  color: #666;
  border-top: 1px solid #ddd;
  margin-top: 20px;
}

.stats span {
  display: flex;
  align-items: center;
  font-weight: bold;
}


.bio {
  font-size: 0.9rem;
  color: #666;
  padding: 0 10px;
}


.special-offer {
  background-color: #f0f0f0;
  padding: 15px;
  margin: 20px 0;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.subscribe-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  width: 100%;
  margin: 5px 0px;
  transition: background-color 0.3s ease;
}

.subscription-options {
  padding: 20px;
  background-color: #fafafa;
  border-radius: 8px;
  margin: 10px 0;
}

.option {
  padding: 10px;
  border: 1px solid #ddd;
  margin: 5px 0;
  text-align: center;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
}

.subscription-content {
  text-align: center;
  font-family: Arial, sans-serif;
  color: #555;
}

.tabs {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  font-size: 1rem;
}

.tab {
  color: #888;
  cursor: pointer;
}

.tab.active {
  color: #000;
  border-bottom: 2px solid #000;
}

.locked-content {
  padding: 50px 0;
  color: #bbb;
  font-size: 1.5rem;
}

.lock-icon {
  font-size: 3rem;
  margin-bottom: 10px;
}

.stats-subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}

.stats {
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-size: 1rem;
  color: #666;
  margin-bottom: 10px;
}

.stats span {
  display: flex;
  align-items: center;
}

.subscribe-button {
  background-color: #00aaff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.subscribe-button:hover {
  background-color: #0088cc;
}


.footer {
  text-align: center;
  margin-top: 20px;
  color: #777;
  font-size: 0.8rem;
}

.footer a {
  color: #777;
  margin: 0 5px;
  text-decoration: none;
}

@media (max-width: 480px) {
  .App {
    padding: 10px;
  }

  .profile-header {
    flex-direction: column;
    align-items: center;
  }

  .profile-pic {
    width: 60px;
    height: 60px;
  }

  .bio {
    font-size: 0.85rem;
  }

  .special-offer {
    font-size: 0.9rem;
  }

  .subscribe-button {
    font-size: 0.9rem;
    padding: 8px 0;
  }

  .subscription-options .option {
    font-size: 0.85rem;
    padding: 8px;
  }
}
